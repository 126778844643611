<template>
    <div class="wrapper phone-details-results">
      <div class="inner-wrapper">
        <div class="form-content">
          <div class="name-box">
              {{formTitle}}填写的表单
          </div>
          <div class="paper-title">
             老师评价问卷调查
          </div>
          <div class="question-box">
              <div v-for="(item,index) in detailsObj" :key="index">
                  <div v-if="item.compType == 'EvaluateTeachers'">
                          <div class="subject-title">
                              以下是对<span>{{item.teacherMes.sub}}-{{item.teacherMes.teacherName}}</span>老师的评价
                          </div>
                          <div class="question-title-inner">
                              <div v-for="(child,childIndex) in item.children" :key="childIndex">
                                  <div class="question-title">{{ child.label }}：</div>
                                  <el-radio-group class="el-radio-group"  v-model="child.value" disabled>
                                      <el-radio v-for="(option,optionIndex) in child.options" :key="optionIndex" :label="option.value">{{ option.label }}</el-radio>
                                  </el-radio-group>
                              </div>
                          </div>
                  </div>
                  <div v-if="item.compType == 'EvaluateTeachersBan'">
                          <div class="subject-title">
                              以下是对<span>{{item.teacherMes.sub}}-{{ item.teacherMes.teacherName }}</span>老师的评价
                          </div>
                          <div class="question-title-inner">
                              <div v-for="(child,childIndex) in item.children" :key="childIndex">
                                  <div class="question-title">{{ child.label }}：</div>
                                  <el-radio-group class="el-radio-group" v-model="child.value" disabled>
                                      <el-radio v-for="(option,optionIndex) in child.options" :key="optionIndex" :label="option.value">{{ option.label }}</el-radio>
                                  </el-radio-group>
                              </div>
                          </div>
                  </div>
                  <div v-else-if="item.compType == 'rate'">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-rate v-model="item.value" class="form-item" disabled></el-rate>
                  </div>
                  <div v-else-if="['multipleSelect'].includes(item.compType)  ">
                    <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                        <el-select v-model="item.defaultValue" class="form-item" style="width:100%;" disabled>
                          <el-option
                          v-for="selectOption in item.options"
                          :key="selectOption.value"
                          :label="selectOption.label"
                          :value="selectOption.value">
                          </el-option>
                      </el-select>
                  </div>
                  <div v-else-if="['address'].includes(item.compType)  ">
                    <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                        <el-select v-model="item.value" class="form-item" style="width:100%;" disabled>
                          <el-option
                          v-for="selectOption in item.options"
                          :key="selectOption.value"
                          :label="selectOption.label"
                          :value="selectOption.value">
                          </el-option>
                      </el-select>
                  </div>
                  <div v-else-if="['singleLineText', 'phoneNumber', 'number','IDCard'].includes(item.compType) ">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-input class="form-item" disabled v-model="item.value"></el-input>
                  </div>
                  <div v-else-if="['descriptionText'].includes(item.compType) ">
                      <div class="question-title" v-if="item.label"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-input class="form-item" disabled v-model="item.value" type="textarea"></el-input>
                  </div>
                  <div v-else-if="['multiLineText'].includes(item.compType) ">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-input class="form-item" disabled v-model="item.value" type="textarea"></el-input>
                  </div>
                  <div v-else-if="item.compType == 'radio'">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-radio-group class="el-radio-group form-item" v-model="item.value"  disabled>
                          <el-radio v-for="(option,optionIndex) in item.options" :key="optionIndex" :label="option.value">{{ option.label }}</el-radio>
                      </el-radio-group>
                  </div>
                  <div v-else-if="item.compType == 'dateTime'">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-date-picker
                          style='width:100%'
                          disabled
                          v-model="item.value"
                          class="form-item"
                          :format="item.format"
                          :value-format="item.format"
                          :type="item.value"
                          :placeholder="item.placeholder">
                      </el-date-picker>
                  </div>
                  <div v-else-if="item.compType == 'upload'">
                      <div class="question-title"><span v-if="item.required" class="star">*</span>{{ item.label }}：</div>
                      <el-image
                          v-if="item.value"
                          class="form-item"
                          style="width: 100px; height: 100px"
                          :src="item.value"
                          @click="showPreview(item.value)"
                          :preview-src-list="srcList"
                          ></el-image>
                        <div v-else>-</div>
                      <!-- <el-upload
                          class="form-item"
                          :action="action">
                          <div class="upload-box">
                              <i class="el-icon-plus"></i>
                          </div>

                      </el-upload> -->
                  </div>
              </div>
          </div>

        </div>

      </div>

    </div>
  </template>

  <script>
  export default {
    props: {
      detailsObj: {
        type: Array
      },
      formTitle:{
          type: String
      }

    },
      data () {
          return {
              action:'',
              srcList:[]
          }
    },
    methods : {

      showPreview(url) {
          this.srcList = [ url ]
      },
    }
  }
  </script>

  <style lang="scss" scoped>
  .wrapper {
    width: 380px;
    height: 80vh;
    background: #fff;
    border-radius: 48px;
    padding: 8px;
    text-align: left;
    box-sizing: border-box;
    .inner-wrapper{
      padding: 10px;
      height: 100%;
      background: #ECECEC;
      border-radius: 48px;
      padding: 43px 18px 33px;
      box-sizing: border-box;
      .form-content{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        overflow:auto;
        .form-item{
          margin-bottom: 10px;
        }
      .paper-tip{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #2B2F33;
          padding: 10px 15px;
          padding-bottom: 5px !important;
          text-align: left;
      }
      .paper-title{
          height: 53px;
          background: #F0F5FF;
          font-family: Source Han Sans CN;
          font-weight: 600;
          font-size: 18px;
          color: #3C7FFF;
          line-height: 53px;
          text-align: center;
          letter-spacing: 2px;
      }
      .question-box{
          padding: 15px;
          overflow:auto;
          .question-title-inner{
              // margin-left:15px;
          }
          .upload-box{
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border: 3px solid #ccc;
              font-size: 30px;
              color: #ccc;
          }
          .star{
              color:red;
              font-size: 14px;
          }
          .subject-title{
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 14px;
              color: #2B2F33;
              margin-bottom:13px;
              span{
                  color:#3C7FFF;
              }
          }
          .question-title{
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #2B2F33;
              margin-bottom: 10px;
          }
          .el-radio-group{
            margin-left: 10px;
          }
          .el-radio{
              display: block;
              margin-bottom: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #2B2F33;
          }
      }
      }
      // border: 8px solid #FFFFFF;
    }

  }
  .name-box{
      background: #FFFBCF;
      height: 50px;
      line-height: 50px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #2B2F33;
      text-align: center;
  }
  </style>
<style lang="scss">
.phone-details-results{
    .el-radio__input.is-checked + .el-radio__label{
        color: #3c7efe !important;
    }
    .el-radio__input.is-disabled.is-checked .el-radio__inner{
        background-color: #3c7efe !important;
        border-color: #3c7efe !important;
    }
    .el-input.is-disabled .el-input__inner{
        color: #2B2F33 !important;
    }
    .el-textarea.is-disabled .el-textarea__inner{
        color: #2B2F33 !important;
    }
}

</style>
