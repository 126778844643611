<template>
    <div class="details-wrapper">
        <div class="details-content">
            <table-data
                v-loading="loadingTable"
                :to-top="false"
                id="table"
                ref="table"
                :config="tableConfig"
                :tableData="tableData"
            >
                <template v-slot:formTitle="slotData">
                    <div
                        style="color: #3c7fff; cursor: pointer"
                        @click="openDetails(slotData.data)"
                    >
                        {{ slotData.data.name }}
                    </div>
                </template>
                <template v-slot:fillStatus="slotData">
                    <StatusCard :status="slotData.data.fillStatus"></StatusCard>
                </template>
                <template v-slot:operation="slotData">
                    <div v-if="slotData.data.fillStatus == 1">
                        -
                    </div>
                    <div v-else>
                        <el-button
                            type="text"
                            @click="showAnswer(slotData.data)"
                            >查看答卷</el-button
                        >
                        <el-button
                            v-if="slotData.data.fillStatus == 3"
                            class="invalid-btn"
                            type="text"
                            @click="markPaper(slotData.data, '有效')"
                            >标记为有效答卷</el-button
                        >
                        <div
                            v-if="slotData.data.fillStatus == 2"
                            @click="markPaper(slotData.data, '无效')"
                            class="wuxiao"
                        >
                            标记为无效答卷
                        </div>
                    </div>

                </template>
            </table-data>
            <Pagination
                :page.sync="pageData.pageNum"
                :limit.sync="pageData.pageRow"
                :total="pageData.total"
                @pagination="changeTableDataGet"
            />
            <dialog-wrapper
                class="details-dia"
                :dialog-obj="detailsDialog"
                @handleClose="handleDetailsClose"
            >
                <div class="import-wrap">
                    <div style="text-align: center">
                        <phone-details
                            :detailsObj="detailsObj"
                            :formTitle="formTitle"
                        ></phone-details>
                    </div>
                    <div class="close-box">
                        <i
                            class="el-icon-circle-close icon-color"
                            @click="handleDetailsClose"
                        ></i>
                    </div>
                </div>
            </dialog-wrapper>
        </div>
    </div>
</template>

  <script>
import {
    Loading,
    Error,
    Pagination,
    DialogWrapper,
    debounce,
} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import PhoneDetails from "@/components/scrollWrapper/InterconnectionInfoCollection/phoneDetailsResults.vue";
import {InterconnectionInfoCollectionStatostics} from "@/models/InterconnectionInfoCollectionStatostics.js";
import StatusCard from "../InterconnectionInfoCollection/StatusCardUser.vue";

export default {
    name: "UserFillingStatus",
    props: {
        id: {
            default: () => {
                return "";
            },
        },
    },
    components: {
        GlobalPageBack,
        TableData,
        PhoneDetails,
        Loading,
        Error,
        Pagination,
        DialogWrapper,
        StatusCard,
    },
    data() {
        return {
            formTitle: "",
            detailPageTitle: "详情",
            loadingTable: false,
            tableData: [{}],
            detailsObj: [],
            pageData: {
                pageNum: 1,
                pageRow: 20,
                total: 0,
            },
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            tableConfig: {
                thead: [
                    {
                        label: "姓名",
                        // labelWidth: '180px',
                        prop: "filledName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.filledName ? row.filledName : "-";
                        },
                    },
                    {
                        label: "组织架构",
                        // labelWidth: '180px',
                        prop: "organName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.organName ? row.organName : "-";
                        },
                    },

                    {
                        label: "表单提交时间",
                        // labelWidth: '180px',
                        prop: "submitTime",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.submitTime ? row.submitTime : "-";
                        },
                    },
                    {
                        label: "来源",
                        // labelWidth: '180px',
                        prop: "source",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            let source = {
                                1: "家长端",
                                2: "教师端",
                                3: "二维码",
                                4: "-",
                            };
                            if (!row.source) {
                                return source["4"];
                            }
                            return source[row.source];
                        },
                    },
                    {
                        label: "状态",
                        minWidth: "210px",
                        type: "slot",
                        slotName: "fillStatus",
                        prop: "fillStatus",
                        tooltip: true,
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "280px",
                        slotName: "operation",
                        className: "text-spacing-reduction",
                        renderHeader: null,
                    },
                ],
                height: "",
                check: false,
            },
        };
    },
    mounted() {
        this.tableConfig.height =
            document.body.clientHeight -
            document.getElementById("table").offsetTop -
            146;
        window.onresize = debounce(() => {
            if (!document.getElementById("table")) return;
            const height =
                document.body.clientHeight -
                document.getElementById("table").offsetTop -
                146;
            this.tableConfig.height = height.toString();
        }, 200);
        this.getList();
    },
    methods: {
        /**
         * @Description: 获取列表数据
         * @Author: 王梦瑶
         * @Date: 2024-08-15 17:37:00
         */
        getList() {
            this.loadingTable = true;
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getSchoolCollectRecordList(
                {
                    schoolId: this.$store.state.schoolId,
                    formId: this.id,
                    pageNum: this.pageData.pageNum,
                    pageRow: this.pageData.pageRow,
                },
            ).then((res) => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data.list;
                    this.pageData.total = res.data.data.total;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }

                this.loadingTable = false;
            });
        },
        /**
         * @Description: 修改有效无效答卷
         * @Author: 周浩
         * @Date: 2024-08-15 17:37:00
         */
        updateStatus(id, fillStatus) {
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.updateStatus({
                id,
                fillStatus,
            }).then((res) => {
                this.$message({
                    type: "success",
                    message: "修改成功!",
                });
                this.getList();
            });
        },
        /**
         * @Description: 修改页码
         * @Author: 王梦瑶
         * @Date: 2024-08-15 17:46:23
         */
        changeTableDataGet() {
            this.getList();
        },
        /**
         * @Description: 打开查看答案弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:58:51
         */
        showAnswer(data) {
            this.formTitle = data.filledName;
            this.getAnwer(data.id);
        },
        getAnwer(id) {
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getAnwer(id).then(
                (res) => {
                    if (res.data.code == 200) {
                        if (
                            !res.data.data.answerInfo ||
                            res.data.data.answerInfo == "{}"
                        ) {
                            this.detailsObj = [];
                        } else {
                            let content = JSON.parse(
                                res.data.data.answerInfo,
                            ).content;
                            let info = JSON.parse(JSON.parse(content));
                            this.detailsObj = info;
                            this.detailsObj.forEach((item)=>{
                                if(item.compType == 'multipleSelect' && item.value){
                                    item.defaultValue = item.value.map((i)=>{
                                        return i.label
                                    })
                                }
                            })
                        }

                        this.detailsDialog.dialogVisible = true;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                    }
                },
            );
        },
        /**
         * @Description: 关闭表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-08 14:57:04
         */
        handleDetailsClose() {
            this.detailsDialog.dialogVisible = false;
        },
        /**
         * @Description: 标记为有效/无效答卷
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:59:07
         */
        markPaper(data, type) {
            let id = data.id;
            let fillStatus = "3";
            let tit = "不";
            if (type === "有效") {
                tit = "";
                fillStatus = "2";
            }

            let msg = `确定将此问卷标记为“${type}答卷”吗？标记后，该答卷数据${tit}会进入到统计结果中。`;
            this.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.updateStatus(id, fillStatus);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消修改",
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
    .details-content {
        height: calc(100vh - 180px);
        background-color: #fff;
    }
}
.invalid-btn {
    color: #e63a2e !important;
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}
.wuxiao {
    color: #e63a2e;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}
</style>
